<template>
  <v-data-table
      :headers="headers"
      :items="transactions"
      :options.sync="options"
      :server-items-length="totalTransactions"
      :loading="loading"
      :footer-props="footerProps"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getTransactionsFromApi"
      @update:sort-desc="getTransactionsFromApi"
      :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@5" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #[`item.type`]="{item}">
      <v-chip
          small
          :color="typeColor[item.type]"
          :class="`${typeColor[item.type]}--text`"
          class="v-chip-light-bg"
      >
        {{ item.type }}
      </v-chip>
    </template>
    <template #[`item.obj_id`]="{item}">
      <span v-if="item.type == 'Payout'">{{ $t("Payout") }}</span>
      <span v-else-if="item.type == 'Order'">
        {{ $t('Order id ') }}
      </span>
      <span v-else-if="item.type == 'Refund'">
        {{ $t('Refund id ') }}
      </span>
      <router-link v-if="item.type == 'Order' || item.type == 'Refund'" style="text-decoration: none" :to="{name: 'seller-order', params: {id: item.obj_id}}">
        {{ item.obj_id }}
      </router-link>
    </template>
    <template #[`item.debit`]="{item}">
      {{ item.debit?`$${Number(item.debit)}`:'-' }}
    </template>
    <template #[`item.credit`]="{item}">
      {{ item.credit?`$${Number(item.credit)}`:'-' }}
    </template>
    <template #[`item.balance`]="{item}">
      {{ `$${Number(item.balance)}` }}
    </template>
  </v-data-table>
</template>

<script>
import axios from "@axios";
import {useRouter} from "@core/utils";
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api";
import {useVModel} from "@vueuse/core";
import {truncate} from "@/plugins/helpers"
import store from '@/store'

export default {
  name: "StoreTransactionsTable",
  setup() {
    const {router, route} = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.sellerTransactions[route.value.params.tab]
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob[route.value.params.tab] = Object.assign({}, val, {
          page
        })
        store.commit("pagination/SET_SELLER_TRANSACTIONS_PAGINATION", ob);
      }
    }))
    const loading = ref(true)
    const transactions = ref([])
    const totalTransactions = ref(0)

    const getTransactionsFromApi = () => {
      loading.value = true
      const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
      const URL = route.value.name == 'admin-transactions-user-store-list' ? 'admin/store-transactions/' + route.value.params.store_id : 'store-transactions'
      axios.get(URL, {
        params: {
          pagination: globalItemsPerPage.value,
          page,
          sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
          sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
          type_tab: route.value.params.tab,
        }
      }).then(res => {
        transactions.value = res.data.data
        totalTransactions.value = res.data.meta.total
        let ob = {}
        ob[route.value.params.tab] = { itemsPerPage: globalItemsPerPage.value, page, lastPage: res.data.meta.last_page }
        store.commit("pagination/SET_SELLER_TRANSACTIONS_PAGINATION", ob)
        loading.value = false
      }).catch(e => console.log(e))
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])

    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
      getTransactionsFromApi()
    }

    getTransactionsFromApi()

    return {
      updateItemsPerPage,
      globalItemsPerPage,
      getTransactionsFromApi,
      options,
      loading,
      transactions,
      totalTransactions,
      truncate,
      typeColor: {
        Payout: 'success',
        Order: 'primary',
        Refund: 'error'
      },
      footerProps: {
        'items-per-page-options': store.getters['nav/itemsPerPageOptions']
      },
      headers: [
        {text: 'Id', value: 'id', width: 340, sortable: false},
        {text: 'Date', value: 'post_date', width: 115},
        {text: 'Type', value: 'type', width: 115, sortable: false},
        {text: 'Description', value: 'obj_id', sortable: false},
        {text: 'Received', value: 'credit', sortable: false, width: 90},
        {text: 'Spend', value: 'debit', sortable: false, width: 90},
        {text: 'Balance', value: 'balance', sortable: false},
      ],
    }
  }
}
</script>
