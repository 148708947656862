var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"options":_vm.options,"server-items-length":_vm.totalTransactions,"loading":_vm.loading,"footer-props":_vm.footerProps,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getTransactionsFromApi,"update:sort-desc":_vm.getTransactionsFromApi},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticStyle:{"width":"90vw","max-width":"1390px"},attrs:{"type":"table-row-divider@5"}})]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.typeColor[item.type]) + "--text"),attrs:{"small":"","color":_vm.typeColor[item.type]}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.obj_id",fn:function(ref){
var item = ref.item;
return [(item.type == 'Payout')?_c('span',[_vm._v(_vm._s(_vm.$t("Payout")))]):(item.type == 'Order')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Order id '))+" ")]):(item.type == 'Refund')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Refund id '))+" ")]):_vm._e(),(item.type == 'Order' || item.type == 'Refund')?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{name: 'seller-order', params: {id: item.obj_id}}}},[_vm._v(" "+_vm._s(item.obj_id)+" ")]):_vm._e()]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.debit?("$" + (Number(item.debit))):'-')+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.credit?("$" + (Number(item.credit))):'-')+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (Number(item.balance))))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }