<template>
  <div class="saa-products-info-container">
    <div :class="{'admin-info': admin}" class="saa-products-info-block" v-for="(info, index) in productsInfo" :key="index">
     <svg width="51" height="50" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.333008" width="40" height="40" rx="5" style="opacity: .2" :fill="blockInfo[index].color"/>
        <path d="M10.333 13.334C10.333 14.5613 11.3279 15.5562 12.5552 15.5562H12.5552H28.1108C29.3381 15.5562 30.333 16.5511 30.333 17.7784V26.6673C30.333 27.8946 29.3381 28.8895 28.1108 28.8895H12.5552C11.3279 28.8895 10.333 27.8946 10.333 26.6673V13.334Z" style="opacity: .4" :fill="blockInfo[index].color"/>
        <path d="M12.5552 15.5558H25.8885V13.1113C25.8885 12.0068 24.9932 11.1113 23.8885 11.1113H12.333C11.2285 11.1113 10.333 12.0068 10.333 13.1113V13.3336C10.333 14.5609 11.3279 15.5558 12.5552 15.5558Z" style="opacity: .7" :fill="blockInfo[index].color"/>
        <path d="M30.3326 25H27.451C25.9903 25.0265 24.712 24.0231 24.3907 22.5979C24.0632 20.971 25.1165 19.3866 26.7434 19.0591C26.9374 19.02 27.1347 19.0003 27.3326 19H30.3326V25Z" :fill="blockInfo[index].color"/>
      </svg>

      <div>
        <span class="info-title">{{ $t(blockInfo[index].title) }}</span><br>
        <span class="col">${{ info }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import {onMounted, computed, ref} from "@vue/composition-api";
import axios from "@axios";
import {useVModel} from '@vueuse/core'
import {useRouter} from "@core/utils"

export default {
  name: "TransactionsInfo",
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const {route} = useRouter()
    const adminData = useVModel(props, 'admin', emit)
    const blockInfo = {
      topUps: { color: '#56ca00', title: 'Top-ups' },
      orders: { color: adminData.value ? '#9155fd' : '#9155fd', title: 'All Orders' },
      refunds: { color: adminData.value ? '#ff4c51' : '#ff4c51', title: 'All Refunds' },
      payouts: { color: adminData.value ? '#FFCF5C' : '#56ca00', title: 'All Payouts' },
      mpProfit: { color: '#32BAFF', title: 'MP Profit' },
    }
    const productsInfo = ref({})
    axios.get(`${adminData.value || route.value.name == 'admin-transactions-user-store-list'  ? 'admin/admin' : 'store'}-transactions-info${route.value.name == 'admin-transactions-user-store-list' ? `/${route.value.params.store_id}` : ''}`).then( res => {
      productsInfo.value = res.data
    })
    return {
      blockInfo,
      productsInfo,
    }
  }
}
</script>