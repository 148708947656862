<template>
  <div v-if="!loading && market" class="saa-transactions-container">
    <account-role-title role="Store" />
    <!-- <breadcrumbs /> -->
    <transactions-info></transactions-info>
    <span class="title">{{ $t("Transactions") }}</span>
    <v-tabs v-model="tab" show-arrows>
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name: $route.name == 'admin-transactions-user-store-list' ? 'admin-transactions-user-store-list' : 'store-transactions', params: $route.name == 'admin-transactions-user-store-list' ? {store_id: $route.params.store_id, tab:tab.route} : {tab:tab.route}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <!-- tab component -->
          <component v-if="tab.route == $route.params.tab" :is="tab.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import StoreTransactionsTable from "@/views/cabinet/transactions/StoreTransactionsTable";
import TransactionsInfo from "@/views/cabinet/transactions/TransactionsInfo";
import {ref, watch, onBeforeMount, computed} from "@vue/composition-api/dist/vue-composition-api";
import {useRouter} from "@core/utils";
import store from '@/store';

export default {
  name: "StoreTransactions",
  components: {
    StoreTransactionsTable,
    TransactionsInfo
  },
  setup() {
    const {route, router} = useRouter()
    const tabs = [
      {title: 'All', route: 'all', component: StoreTransactionsTable},
      {title: 'Orders', route: 'orders', component: StoreTransactionsTable},
      {title: 'Refunds', route: 'refunds', component: StoreTransactionsTable},
      {title: 'Payouts', route: 'payouts', component: StoreTransactionsTable}
    ]
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    onBeforeMount(() => store.dispatch('market/getMarket'))
    const market = computed(() => {
      if(!store.state.market.market && route.value.name != 'admin-transactions-user-store-list') router.push({name: 'user-market', params:{tab:'all'}})
      return store.state.market.market
    })
    watch(() => route.value.params.tab, (newTab) => tab.value = tabs.findIndex(tab => tab.route === newTab))
    return {
      tabs,
      tab,
      market,
      loading: computed(() => store.state.market.loading),
    }
  }
}
</script>
